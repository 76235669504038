import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import MetaCards from "../components/common/meta-cards"
import Hero from "../components/contact/hero"
import ContactSection from "../components/contact/contact"
import ReferralForm from "../components/contact/referral_form"

const Contact = ({ data }) => {
  return (
    <Layout>
      <Seo title="Contact" />
       <MetaCards data={data.contentfulContactPage.webMeta}/>
      <Hero />
      <ContactSection />
      <ReferralForm data={data.contentfulContactPage} />
    </Layout>
  )
}

export default Contact

export const query = graphql`
  {
    contentfulContactPage {
            webMeta {
        pageTitle
        pageMetaDescription
        pageMetaKeywords
      }
      referralLinks {
        id
        title
        content {
          content
        }
        url
      }
      referralSlider {
        id
        testimonial {
          testimonial
        }
        client
      }
    }
  }
`
